@import '../../variables.scss';

.grid{
    z-index: -1;
    position: fixed;
    bottom: -100px;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (orientation:portrait) { 
        height: 25vh;
        width: calc(25vh * 3.6);
    }

    @media screen and (orientation:landscape) { 
        width: 100vw;
        height: calc(100vw / 3.6);
    }

}

.tiles{
    path{
        transition:fill 3s ease;
    }
}