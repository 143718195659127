@import '../../variables.scss';

header{
    padding:30px 45px 0px;
    position:fixed;
    z-index:1000;
    top:0;
    width:100%;
    display:flex;
    justify-content: space-between;
    align-items: center;

    svg{
        height:50px;
        width:auto;
        cursor: pointer;

        @media(min-width:768px){
            height:75px;
        }

        #business-name{
            visibility:hidden;

            @media(min-width:768px){
                visibility: visible;
            }
        }
    }

    .navicon{
        appearance:none;
        border:0;
        padding:0;
        margin:0;
        background:transparent;
        width:60px;
        height:50px;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background:$white;
        padding:10px;
        cursor: pointer;
        
        span{
            width:100%;
            height:5px;
            background:$red;
        }
    }
}

nav{
    display:none;
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;

    a{
        cursor: pointer;
        font-family: "Barlow", sans-serif;
        font-size:30px;
        font-weight:300;
        color:$red;
        text-decoration: none;
        display:block;
        text-align: center;
        padding:15px 0;

        @media(min-width:1280px){
            font-size:45px;
            padding:20px 0;
        }

        &:hover{
            color:$green;
        }
    }
}

html{
    section{
        opacity:1;
        transition:opacity 300ms ease;
    }
}

html.shownav{
    nav{
        display:flex;
    }
    section{
        opacity:0;
    }
}