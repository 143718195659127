@import '../../variables.scss';

.pricing{
    .tiers{
        width:100%; 

        @media(min-width:768px){
            display:flex;
            justify-content: space-between;
        }

        > div{

            @media(min-width:768px){
                width:calc(50% - 15px);
            }

            @media(min-width:1280px){
                width:calc(33% - 30px);
            }

            h3{
                margin-top:60px; 

                + p{
                    margin-top:10px;
                }
            }

            &.otherprices{
                @media(min-width:768px){
                    display:flex;
                    flex-direction:column;
                    justify-content: space-between;
                }

                > div{
                    @media(min-width:768px){
                        flex-grow:1;
                    }
                }
            }
        }

        .oldway,
        .oldprice,
        .newway{
            display:none;

            @media(min-width:1280px){
                display:block;
            }
        }

        .price{
            border-width:5px;
            border-style: solid;
            border-radius:30px 0 0 0;
            background:#fff;
            padding:45px;
            margin-top:45px;
            position:relative;

            > *{
                position: relative;
                z-index: 1;
            }

            &.monthprice{
                border-color:$blue;
                display:flex;
                flex-direction: column;
                justify-content: space-between;

                strong{
                    color:$blue;
                }

                ul{
                    flex-grow:1;
                }

                ul li:last-of-type{
                    font-weight:bold;
                    color:$blue;
                }

                .btn{
                    background:$blue;

                    &:hover{
                        background:$red;
                    }
                }
            }

            &.quarterprice,
            &.yearprice{
                border-color:$green;

                > strong{
                    color:$green;
                }

                ul li strong{
                    font-weight:700;
                }

                .btn{
                    background:$green;

                    &:hover{
                        background:$red;
                    }
                }                
            }   
            
            &.oldprice{
                border-color:$grey;

                > strong{
                    color:$grey;
                    text-decoration: line-through;
                }
            }

            > strong{
                font-size:45px;
                font-family: 'Barlow', sans-serif;
                font-weight:700;
                
                small, span{
                    font-size:30px;
                    font-weight:500;
                    display:inline-block;
                }

                small{
                    margin-right:10px;
                }

                span{
                    margin-left:10px;
                    color: $black !important;
                }
            }

            ul{
                margin-top:30px;

                li{
                    display:block;
                    margin-top:15px;

                    @media(min-width:1280px){
                        font-size:18px;
                    }
                }
            }

            .btn{
                display:block;
                width:100%;
                max-width:none;
            }

            > svg{
                position:absolute;
                bottom:0;
                right:0;
                width:33%;
                height:auto;
                z-index:0 !important;
            }
        }
    }
}