@import '../../variables.scss';

#conversational-form {
    position: relative;
    height: 45vh;
    width: 86vw;
    max-width: 550px;
    background: transparent;
    margin:45px 0;

    *{
        font-family: 'Jost', sans-serif;
    }

    &:before{
        display:none;
    }

    cf-input{
        background:transparent;

        input[type="input"],
        input[type="email"] {
            background: #fff;
            border-radius: 15px;
            border: 2px solid black;
            opacity:1;
            transition:opacity 2s ease !important;
            box-shadow: -6px -6px 0px $black, 
                        -5px -5px 0px $black, 
                        -4px -4px 0px $black, 
                        -3px -3px 0px $black, 
                        -2px -2px 0px $black, 
                        -1px -1px 0px $black;
            animation:BLACKBOXSHADOW 2s ease backwards;
        }

        .inputWrapper{
            animation: FADEIN 2s 3s ease both;
        }
    }

    cf-chat-response{
        animation: SLIDEUP 500ms ease both, FADEIN 500ms ease both;

        text{
            p{
                font-size:18px;
                line-height:1.5;
            }
            strong {
                font-weight: 700;
            }
        }

        &.user{
            padding-right:0px !important;

            thumb{
                display:none !important;
            }
            text{
                p{
                    background-color:#000;
                }
            }
        }

        &.robot{
            thumb{
                background-size:25px 25px;
                background-color:$red;
            }
            text{
                p{
                    background-color:#e9e9e9;
                }
            }
        }
    }

    cf-input-button {
        border-radius: 100%;
        overflow: hidden;
        height: 30px;
        width: 30px;
        right: 15px;
        bottom: 15px;
        background-color:$red;
        
        .cf-icon-progress{
            background-image:url('send.svg');
            background-size: 15px 15px;
            animation:none !important;
        }
    }

    .conversational-form-inner {
        -webkit-mask-image: -webkit-gradient(linear, left 30%, left top, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    }
}

@media(min-width:1024px){
    #conversational-form{
        width: 40vw;
    }
}