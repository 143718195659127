@import '../../variables.scss';

.design{
    .portfolio{
        display:flex;
        justify-content: space-between;
        flex-wrap:wrap;
        gap:30p;
        margin-top:45x;

        video{
            width:100%;
            height:45vw;
            margin-top:60px;
            border:5px solid $black;
            background:$black;
            transition: all 500ms ease;
            box-shadow:-6px -6px 0px $black, 
                -5px -5px 0px $black, 
                -4px -4px 0px $black, 
                -3px -3px 0px $black, 
                -2px -2px 0px $black, 
                -1px -1px 0px $black;

            @media(min-width:768px){
                width: calc(50% - 30px);
                height: 22vw;
                margin-top: 60px;
            }

            @media(min-width:1440px){
                width: calc(33% - 30px);
                height:14vw;
            }

            @media(min-width:1920px){
                height: 255px;
            }

            &:hover{
                box-shadow:none;
                transform:translate3d(-5px,-5px,0);
                border-color:$red;
                background:$red;
            }
        }
    }
}