@import '../../variables.scss';

.faqs{
    max-width:750px;
    margin-top:45px;

    .faq{
        margin-top:45px;
        padding:5px 0 5px 30px;
        border-left:5px solid $black;
        transition:all 300ms;
        cursor:pointer;

        &:hover{
            border-color:$red;
        }

        p{
            max-height:0px;
            overflow:hidden;
            margin-top:0;
            transition:all 500ms;
            opacity:0;
        }

        &.open{
            border-color:$green;

            p{
                max-height:1000px;
                margin-top:30px;
                opacity:1;
            }
        }
    }
}