@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,500;0,700;1,100;1,700&family=Jost:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import 'variables.scss';

*, *:before, *:after{
    box-sizing: border-box;
}

html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video{
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: none;
}

footer, header, nav, section, main{
    display: block;
}

html, body{
    line-height: 1;
    overflow-x:hidden;
}

ol, ul{
    list-style: none;
}

blockquote, q{
    quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after{
    content: '';
    content: none;
}

table{
    border-collapse: collapse;
    border-spacing: 0;
}

input{
    appearance: none;
    border-radius: 0;
}

/* TEXT */

h1, h2, h3, h4, h5, h6{
    font-family:'Barlow', 'san-serif';
    font-weight:700;
    text-align: center;
    line-height:1.15;
    text-align: left;
    display:block;
    width:100%;

    strong{
        font-style: oblique;
    }
}

h1, h2{
    margin-top:5vh;

    @media(min-width:768px){
        text-align: center;
    }

    + p{
        text-align: left;
        font-size:22px;

        @media(min-width:768px){
            max-width:500px;
            text-align:center;
            font-size:26px;
        }
    }

    + cite {
        font-size: 18px;
        line-height: 1.5;
        font-style:normal;
        display:block;
        margin-top:30px;
        text-align:left;
        max-width:600px;

        @media(min-width:768px){
            text-align: center;
        }

        strong{
            display:block;
            font-weight:bold;
            text-transform:uppercase;
            font-size:15px;
            display:block;
            margin-top:15px;
        }
    }
}

h1{
    font-size:calc(30px + 3vw);
    max-width: calc(600px + 15vw);
    margin-bottom:1vw;

    + p{
        max-width:600px;
    }

    strong{
        color: $green;
    }
}

h2{
    font-size:calc(30px + 1.5vw);

    strong{
        color: $red;
    }
}

h3{
    font-size:30px;

    strong{
        @media(min-width:960px){
            display:block;
        }
        
        @media(min-width:1280px){
            display:inline;
        }
    }
}

p{
    font-size:18px;
    line-height:1.5;
    margin-top:30px;
    text-align: left;
}

ul{
    li{
        line-height:1.5;
        font-weight:500;
        font-size:18px;        
    }
}

/* SECTIONS */

section{
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    min-width:100%;
    min-height:100vh;
    padding:15vh 10vw;
    font-family: 'Jost', sans-serif;
    position: relative;
    z-index: 1;
}

.container{
    width:100%;
    max-width:1440px;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;    
}

/* BUTTONS */

.btn{
    font-family: 'Barlow', sans-serif;
	background:$red;
	border:3px solid $black;
	padding:12.5px 15px;
    text-align: center;
    width:100%;
	appearance: none;
	text-transform: uppercase;
	font-weight:600;
	font-size:20px;
    letter-spacing:1px;
	color:$white;
	text-decoration: none;
	cursor: pointer;
	transform:translate3d(0,0,0);
	box-shadow:-6px -6px 0px $black, 
                -5px -5px 0px $black, 
                -4px -4px 0px $black, 
                -3px -3px 0px $black, 
                -2px -2px 0px $black, 
                -1px -1px 0px $black;
	transition: all 500ms ease;
    margin-top:45px;

    @media(min-width:768px){
        max-width:300px;
    }

	&:hover{
		background: $green;
		box-shadow:none;
		transform:translate3d(-5px,-5px,0);
		color: $white;
	}
}

.link{
    margin-top:60px;
    font-size:20px;
    text-decoration: underline;
    font-weight:600;
    color:$black;
    cursor: pointer;
}